<template>
  <div class="scheme-contain">
    <div class="drug-box">
      <Form :label-width="110">
        <Row>
          <Col span="8" offset="7">
            <Form-item label="类型：">
              <Select placeholder="请选择" clearable v-model="addForm.drugTypeId" @on-change="handleType">
                  <Option v-for="item in typeList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </Form-item>
            <Form-item label="名称：">
              <Select filterable clearable placeholder="请选择" v-model="addForm.drugNameId" @click.native="handleNameInfo">
                <Option v-for="item in drugList" :value="item.value" :key="item.id">{{ item.label }}</Option>
              </Select>
            </Form-item>
            <Form-item label="使用方法：">
              <Input v-model="addForm.drugWay" type="textarea" placeholder="举例：一日3次，早2粒中1粒晚1粒..." :maxlength="100"></Input>
              <p class="drug-text">注：最多只能输入100字</p>
            </Form-item>
            <Form-item label="使用开始时间：">
              <Date-picker type="date" placeholder="选择日期" @on-change="changeTime" @on-clear="handleStartTime" :value="addForm.startTime"></Date-picker>
            </Form-item>
            <Form-item label="服用依从性：">
              <Select placeholder="请选择" v-model="addForm.complianceId">
                <Option v-for="item in complianceData" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </Form-item>
            <Form-item label="采购地点：">
              <Input placeholder="请输入采购地点..." type="textarea" v-model="addForm.place" :maxlength="100"></Input>
              <p class="drug-text">注：最多只能输入100字</p>
            </Form-item>
            <Form-item label="寄存数量：">
              <Input placeholder="请输入寄存数量..." type="text" v-model="addForm.num"></Input>
            </Form-item>
          </Col>
        </Row>
        <div class="symbol-box tac">
          <Button type="primary" size="large" @click="handleBack">返回</Button>
          <Button type="success" size="large" @click="handleSave">保存</Button>
        </div>
      </Form>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			drugList: [],
			typeList: [],
			allData: [],
			addForm: {
				drugNameId: '',
				drugTypeId: '',
				drugWay: '',
				startTime: '',
				complianceId: '',
				place: '',
				num: '',
			},
			complianceData: [],
		};
	},

	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},

	created() {
		MemberService.listDrugName().then((data) => {
			this.allData = data;
		});
		MemberService.listDrugType().then((data) => {
			this.typeList = data;
		});
		this.getComplianceList();
	},

	methods: {
		// 获取服药服从性列表
		getComplianceList() {
			MemberService.complianceList().then((data) => {
				this.complianceData = data;
			});
		},
		changeTime(date) {
			this.addForm.startTime = date;
		},

		validateFormData() {
			if (!this.addForm.drugNameId) {
				this.$Message.warning('请选择成药名称');
				return false;
			} else if (this.addForm.drugWay.length > 100) {
				this.$Message.warning('最多只能输入100字');
				return false;
			} else if (!this.addForm.startTime) {
				this.$Message.warning('请选择时间');
				return false;
			} else if (!this.addForm.complianceId) {
				this.$Message.warning('请选择用药服从性');
				return false;
			} else if (!this.addForm.place) {
				this.$Message.warning('请输入采购地点');
				return false;
			} else if (this.addForm.place.length > 100) {
				this.$Message.warning('最多只能输入100字');
				return false;
			} else {
				return true;
			}
		},

		handleSave() {
			const reqData = {
				member_id: this.memberId,
				type_id: this.addForm.drugNameId,
				way: this.addForm.drugWay,
				start_time: this.addForm.startTime,
				compliance_id: this.addForm.complianceId,
				place: this.addForm.place,
				num: this.addForm.num,
			};
			if (this.validateFormData()) {
				MemberService.addDrug(reqData).then(() => {
					// this.$Message.success('成药与要素信息增加成功')
					this.$router.go(-1);
				});
			}
		},

		handleBack() {
			this.$router.go(-1);
		},

		init() {
			this.allData.forEach((item) => {
				if (item.value === this.addForm.drugTypeId) {
					this.drugList = item.children;
					return;
				} else {
					this.addForm.drugNameId = '';
				}
			});
		},
		handleType(value) {
			this.addForm.drugTypeId = value;
			this.init();
		},
		handleNameInfo() {
			if (!this.addForm.drugTypeId) {
				this.drugList = [];
				this.$Message.warning('请先选择类型');
			}
		},
		handleStartTime() {
			this.addForm.startTime = '';
		},
	},
};
</script>
<style lang="css" scoped>
.drug-box {
  padding-top: 20px;
}
.drug-text {
  color: #999;
}
</style>
